body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.circle-sketch-highlight {
  position: relative;
  font-weight: "bold";
}

.circle-sketch-highlight:before {
  content: "";
  z-index: -1;
  left: -0.5em;
  top: -0.2em;
  border-width: 4px;
  border-style: solid;
  border-color: red;
  position: absolute;
  border-right-color: transparent;
  width: 130%;
  height: 1.6em;
  transform: rotate(2deg);
  opacity: 0.7;
  border-radius: 50%;
  padding: 0.1em 0.25em;
}

.circle-sketch-highlight:after {
  content: "";
  z-index: -1;
  left: -0.5em;
  top: -0.1em;
  padding: 0.1em 0.25em;
  border-width: 4px;
  border-style: solid;
  border-color: red;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  width: 130%;
  height: 1.7em;
  transform: rotate(-1deg);
  opacity: 0.7;
  border-radius: 50%;
}
